import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({encodingType:'aes',isCompression:false});
Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  strict: process.env.NODE_ENV !== 'production',
  state(){
    return {
      loggedUser: {},
      token: "",
      didLogout:false,
      isAuthenticated:false,
      loggedRole:"",
    };
  },
  plugins:[createPersistedState({
    storage:{
      getItem: key => ls.get(key),
      setItem:(key,value) => ls.set(key,value),
      removeItem:key => ls.remove(key),
    }
  })],
  mutations:{
    setUser(state, payload) {
      state.token = payload.token;
      state.loggedUser = payload.user;
      state.isAuthenticated=true;
      state.didLogout=false;
    },
    setAutoLogout(state){
      state.didLogout=true;
      state.isAuthenticated=false;
    },
    setLoggedUserRole(state,payload){
      state.loggedRole=payload.role
    }
  },
  actions:{
    async login(context, payload) {
      let credentials = new FormData();
      credentials.append("emailID", payload.emailID);
      credentials.append("password", payload.password);
      const response = await axios.post(payload.path, credentials);
      const responseData = response;
      if (!response.status) {
        throw new Error(
            responseData.message || "Failed to authenticate."
        );
      }
      axios.defaults.headers.common["Authorization"] =
          "Bearer " + responseData.data.data.token;
      localStorage.setItem("token", responseData.data.data.token);
      localStorage.setItem("loginType",responseData.data.data.login_type);
      let role="";
      if(responseData.data.data.login_type===1)
      {
        role= "admin";
      }
      if(responseData.data.data.login_type===2)
      {
        role= "restaurant";
      }
      if(responseData.data.data.login_type===3)
      {
        role= "corporate";
      }
      if(responseData.data.data.login_type===4)
      {
        role= "principle";
      }
      context.commit("setUser", {
        token: responseData.data.data.token,
        user: responseData.data.data,
        isAuthenticated:true,
      });
      context.commit("setLoggedUserRole",{
        role:role,
      });

    },

    async autoLogin(context) {
      const token = localStorage.getItem("token");
      if(!token) return;
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      // if(sessionStorage.getItem("user"))
      // {
      //   let user = JSON.parse(sessionStorage.getItem("user"));
      //   localStorage.setItem("loginType",user.login_type);
      //   context.commit("setUser", {
      //     token: token,
      //     user: user,
      //     isAuthenticated:true,
      //   });
      // }
      // else{
        const response = await  axios.get('check-user-profile');
        if(response.status==200)
        {
          localStorage.setItem("loginType",response.data.data.login_type);
          let role="";
          if(response.data.data.login_type===1)
          {
            role= "admin";
          }
          if(response.data.data.login_type===2)
          {
            role= "restaurant";
          }
          if(response.data.data.login_type===3)
          {
            role= "corporate";
          }
          if(response.data.data.login_type===4)
          {
            role= "principle";
          }
          context.commit("setUser", {
            token: token,
            user: response.data.data,
            isAuthenticated:true,
          });
          context.commit("setLoggedUserRole",{
            role:role,
          });
        }
    },
    logout(context) {
      localStorage.removeItem("token");
     localStorage.removeItem("vuex");
      context.commit("setUser", {
        token: null,
        user: {},
      });
      context.commit('setAutoLogout');
      context.commit("setLoggedUserRole",{
        role:null,
      });
      window.location.reload();
    },

  },
  getters:{
    loggedInUser(state) {
      return state.loggedUser;
    },
    token(state) {
      return state.token;
    },
    isAuthenticated(state){
      return !!state.isAuthenticated;
    },
    didLogout(state){
      return state.didLogout;
    },
    loggedRole(state){
      return state.loggedRole;
    }
  },

})

export default store

