import Vue from "vue";
import BootstrapVue, { FormPlugin } from "bootstrap-vue";
import router from "./router";
import vco from "v-click-outside";
import VueApexCharts from "vue-apexcharts";
import VueSweetalert2 from "vue-sweetalert2";
import VueSlideBar from "vue-slide-bar";
import Vuelidate from "vuelidate";
import i18n from "./i18n";
import store from "@/state/store";
import App from "./App.vue";
// As a plugin
import VueMask from "v-mask";
import * as VueGoogleMaps from "vue2-google-maps";
import Lightbox from "vue-easy-lightbox";

//// axois imported globally ////////
import axios from "axios";
import VueAxios from "vue-axios";
import "sweetalert2/dist/sweetalert2.min.css";
//////// menu bar /////////////////////////
import VueSidebarMenu from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
/////// Download excel ///////////////////
import JsonExcel from "vue-json-excel";
import { func } from "./helpers/helper.js";
import { initFirebaseBackend } from "./helpers/firebase/authUtils";

import { configureFakeBackend } from "./helpers/fakebackend/fake-backend";
import "@/assets/scss/app.scss";

import { abilitiesPlugin } from '@casl/vue';
import {ability} from './service/ability';

Vue.use(FormPlugin);
Vue.config.productionTip = false;
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
// axios.defaults.headers.common["Content-Type"] = "application/json";
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
// axios.defaults.headers.common["Access-Control-Allow-Credentials"] = "true";
Vue.use(VueAxios, axios);
Vue.use(VueSweetalert2);
Vue.use(VueSidebarMenu);
Vue.component("downloadExcel", JsonExcel);
Vue.use(JsonExcel);
Vue.use(abilitiesPlugin,ability);

//////// Global variable for alert noti ///////////////
Vue.prototype.$alertText = "hiii";

Vue.mixin({
  data: function() {
    return {
      showDismissibleAlert: true,
      alertvariant: "success",
      notiTitle: "",
      notiText: ""
    };
  }
});

Vue.use(Lightbox);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places"
  },
  installComponents: true
});

Vue.prototype.$func = func;

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
  configureFakeBackend();
}

Vue.component("VueSlideBar", VueSlideBar);
Vue.use(BootstrapVue);
Vue.use(vco);
Vue.component("apexchart", VueApexCharts);
Vue.use(Vuelidate);
Vue.use(require("vue-chartist"));
Vue.use(VueMask);
axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
          if(error.response.status >=500){
              Vue.swal({
                  title:"Server Error!",
                  text: "Something went wrong !",
                  icon: "error"
              });
          }
          else if(error.response.status==403){
              Vue.swal({
                  text: error.response.data.message,
                  icon: "error",
                  backdrop:false,
                  allowOutsideClick:false,
                  allowEscapeKey:false,
                  allowEnterKey:false,
              });
          }

      }
      return Promise.reject(error)
    },
);
new Vue({
  router,
  i18n,
  render: h => h(App),
  store,
}).$mount("#app");
