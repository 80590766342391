import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import store from '@/state/store'
import routes from './routes'

Vue.use(VueRouter)
Vue.use(VueMeta, {
    // The component option name that vue-meta looks for meta info on.
    keyName: 'page',
})

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                x: 0,
                y: 0
            }
        }
    },
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
    if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
        const authRequired = routeTo.matched.some((route) => route.meta.authRequired)

        if (!authRequired) return next()

        // If auth is required and the user is logged in...
        if (store.getters['auth/loggedIn']) {
            // Validate the local user token...
            return store.dispatch('auth/validate').then((validUser) => {
                // Then continue if the token still represents a valid user,
                // otherwise redirect to login.
                validUser ? next() : redirectToLogin()
            })
        }

        // If auth is required and the user is NOT currently logged in,
        // redirect to login.
        redirectToLogin()

        // eslint-disable-next-line no-unused-vars
        // eslint-disable-next-line no-inner-declarations
        function redirectToLogin() {
            // Pass the original route to the login component
            next({
                name: 'login',
                query: {
                    redirectFrom: routeTo.fullPath
                }
            })
        }
    }

    else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
        const publicPages = ['/login', '/register', '/auth/recoverpwd/1', '/auth/recoverpwd/2','/auth/recoverpwd/3','/auth/recoverpwd/4', '/forgot-password','/restaurantLogin','/corporateLogin','/mainLoginPage','/recoverpwd','/principleLogin'];
        const authpage = !publicPages.includes(routeTo.path);
        // const loggeduser = store.getters('loggedInUser');  //    localStorage.getItem('user'); // this.$store.state.loggedUser;  //;
        const loginType = JSON.parse(localStorage.getItem('loginType'));
        if (authpage && !localStorage.getItem('token')) {
            if(loginType==1){

                localStorage.removeItem('loginType');
                return next('/login');
            }else if(loginType==2){
                localStorage.removeItem('loginType');
                return next('/restaurantLogin');
            }else if(loginType==3){
                localStorage.removeItem('loginType');
                return next('/corporateLogin');
            }else if(loginType==4){
                localStorage.removeItem('loginType');
                return next('/principleLogin');
            }
            else{
                localStorage.removeItem('loginType');
                return next('/mainLoginPage');
            }

        }

        next();
    }
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
    try {
        // For each matched route...
        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        // If the user chose to redirect...
                        if (args.length) {
                            // If redirecting to the same route we're coming from...
                            // Complete the redirect.
                            next(...args)
                            reject(new Error('Redirected'))
                        } else {
                            resolve()
                        }
                    })
                } else {
                    // Otherwise, continue resolving the route.
                    resolve()
                }
            })
        }
        // If a `beforeResolve` hook chose to redirect, just return.
    } catch (error) {
        return
    }

    // If we reach this point, continue resolving the route.
    next()
})

export default router